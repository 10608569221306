@import "../../styles/variable";

.wrapper {
  position: fixed;
  right: -110%;
  width: 1089px;
  min-height: 100vh;
  height: 100%;
  padding: 80px 200px;
  background-color: $white;
  border-left: 1px solid rgba($black, 5%);
  z-index: 11;
  overflow: auto;
  transition: 0.5s ease;
  @media (max-width: 1150px) {
    padding: 50px;
    width: auto;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    min-height: auto;
    padding: 20px;
  }
  &.show {
    right: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close {
      background-color: $bgGrey;
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
    }
    h4 {
      font-size: 15px;
      line-height: 21px;
      color: $heading;
    }
  }
  h2 {
    font-family: "TWK";
    font-weight: 400;
    font-size: 62px;
    letter-spacing: -3px;
    color: $heading;
    margin-top: 20px;
    margin-bottom: 12px;
    @media (max-width: 1024px) {
      margin-bottom: 8px;
      letter-spacing: -1px;
      font-size: 42px;
    }
    @media (max-width: 768px) {
      margin-bottom: 8px;
      letter-spacing: -1px;
      font-size: 38px;
    }
  }
  p {
    font-size: 20px;
    line-height: 26px;
    color: $text;
    margin-bottom: 30px;
    @media (max-width: 1024px) {
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  form {
    .input {
      width: 100%;
      border-radius: 8px;
      background-color: rgba(#f6f6f6, 70%);
      border: 1px solid rgba($black, 6%);
      &:not(:last-child) {
        margin-bottom: 10px;
        @media (max-width: 1024px) {
          margin-bottom: 12px;
        }
      }
      input,
      textarea,
      select {
        height: 54px;
        padding-left: 17px;
        border-radius: inherit;
        width: 100%;
        background-color: transparent;
        font-size: 16px;
        color: #4f4f4f;
        @media (max-width: 1024px) {
          height: 44px;
        }
        @media (max-width: 768px) {
          height: 38px;
          padding-left: 10px;
          font-size: 14px;
        }
      }
      textarea {
        padding-top: 10px;
        resize: none;
        min-height: 230px;
        @media (max-width: 768px) {
          min-height: 130px;
        }
      }
    }
    button {
      width: 100%;
      border-radius: 8px;
    }
    p.error {
      color: red;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}
