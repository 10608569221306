@import "../../styles/variable";

.heroSection {
  margin-top: 80px;
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($primary, 10%);
  padding-block: 30px;
  .heroContent {
    text-align: center;
    max-width: 768px;
    width: 100%;
    margin-inline: auto;
    h5 {
      color: $primary;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
    h2 {
      color: $heading;
      font-size: 62px;
      line-height: 58px;
      font-family: "TWK";
      font-weight: 400;
      margin-bottom: 24px;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 38px;
        line-height: 44px;
        margin-bottom: 14px;
      }
    }
    p {
      color: $text;
      font-size: 18px;
      line-height: 28px;
      font-family: "TWK";
      font-weight: 400;
      letter-spacing: 0.2px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

.contentSection {
  max-width: 768px;
  margin-inline: auto;
  h3 {
    color: #101828;
    font-size: 30px;
    line-height: 38px;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 8px;
    margin-top: 10px;
    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  ul {
    li {
      padding-left: 10px;
      margin-left: 20px;
      padding-bottom: 4px;
      list-style-type: disc;
    }
  }
  p {
    color: $text;
    font-size: 18px;
    line-height: 28px;
    a {
      color: $primary;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
