@import "../../styles/variable";

.popup {
  padding: 10px 16px 16px;
  border-radius: 12px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 12px 16px -4px rgba(#101828, 8%),
    0px 4px 6px -2px rgba(#101828, 3%);
  position: fixed;
  right: 10%;
  bottom: 10%;
  max-width: 359px;
  background-color: $white;
  z-index: 2;
  @media (max-width: 600px) {
    right: 4%;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 10px;
  }
  .content {
    h3 {
      font-size: 14px;
      line-height: 20px;
      color: #101828;
      padding-bottom: 5px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      a {
        color: $primary;
      }
    }
  }
}
