@import "../../styles/_variable.scss";

header {
  padding-block: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(20px);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-width: 1380px;
  width: 100%;
  padding-inline: 20px;
  top: 0;
  .logo {
    display: inline-block;
    position: relative;
    svg {
      @media (max-width: 600px) {
        width: 100px;
      }
    }
    &:hover {
      .animate {
        transform: rotate(45deg);
        transform-origin: 22px 22px;
      }
    }
    .animate {
      transform-origin: 22px 22px;
      transition: 0.3s linear;
    }
  }
  .donorReceiver {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #d0d5dd;
    border-radius: 10px;
    padding: 10px 16px;
    @media (max-width: 768px) {
      padding: 8px;
      gap: 5px;
    }
    span {
      font-size: 16px;
      color: #344054;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .toggleContainer {
      position: relative;
      width: 44px;
      height: 24px;
      @media (max-width: 768px) {
        width: 38px;
        height: 19px;
      }
      .toggleCheckbox {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .toggleLabel {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $bgGrey;
        transition: background-color 0.4s ease;
        border-radius: 34px;
        &:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 2px;
          bottom: 2px;
          background-color: $primary;
          transition: transform 0.4s ease;
          border-radius: 50%;
          box-shadow: 0 1px 2px rgba(#101828, 6%);
          @media (max-width: 768px) {
            height: 16px;
            width: 16px;
            left: 1px;
            bottom: 1.4px;
          }
        }
      }
      .toggleCheckbox:checked + .toggleLabel {
        &:before {
          transform: translateX(20px);
        }
      }
    }
  }
  .right {
    @media (max-width: 768px) {
      display: none;
    }
    display: flex;
    align-items: center;
    gap: 26px;
    a {
      color: $heading;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 2px solid $heading;
      transition: 0.3s ease;
      &:hover {
        border-bottom: 2px solid $primary;
      }
    }
  }
}
