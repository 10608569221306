footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 48px;
  @media (max-width: 992px) {
    padding-bottom: 24px;
    padding-top: 0;
    flex-direction: column;
    gap: 15px;
  }
  .copyright {
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    @media (max-width: 992px) {
      order: 3;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .logo {
    display: inline-block;
    position: relative;
    @media (max-width: 992px) {
      order: 1;
    }
    &:hover {
      .animate {
        transform: rotate(45deg);
        transform-origin: 22px 22px;
      }
    }
    .animate {
      transform-origin: 22px 22px;
      transition: 0.3s linear;
    }
  }
  .pages {
    @media (max-width: 992px) {
      order: 2;
    }
    span {
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      padding-inline: 6px;
    }
    a {
      font-size: 16px;
      line-height: 24px;
      color: #2f343e;
      text-decoration: none;
      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
