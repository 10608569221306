@import "../../styles/variable";

.primary-button {
  background-color: $primary;
  min-width: 150px;
  min-height: 44px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  transition: 0.3s linear;
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 0px rgba(20, 110, 245, 0);
  color: $white;
  font-size: 16px;
  padding-inline: 20px;
  z-index: 1;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
    min-height: 42px;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
  svg {
    transition: 0.3s linear;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.2);
    transition: 0.4s linear;
    z-index: -1;
    transform: skewX(-20deg);
    left: -120%;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.2);
    transition: 0.4s linear;
    z-index: -1;
    transform: skewX(20deg);
    right: -120%;
  }
  &:hover {
    box-shadow: 0px 6px 12px rgba($primary, 0.6);
    svg {
      transform: translateX(5px);
    }
    &::before {
      left: 120%;
    }
    &::after {
      right: 120%;
    }
  }
}
