/* Basic CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}
html,
body {
  height: 100%;
  font-family: "Inter", sans-serif;
}
img,
video {
  max-width: 100%;
  height: auto;
}

button,
input,
select,
textarea {
  font-family: inherit; /* Inherit fonts to ensure consistency */
  outline: none;
  background-color: transparent;
  border: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
.team-section .slick-slider {
  @media (max-width: 992px) {
    padding-bottom: 50px;
  }
}
.team-section .slick-prev {
  width: 30px;
  height: 30px;
  top: 95%;
  left: 40%;
}
.team-section .slick-next {
  width: 30px;
  height: 30px;
  top: 95%;
  right: 40%;
}
.team-section .slick-prev,
.team-section .slick-next {
  color: black !important;
  &::before {
    color: black !important;
  }
}
