@import "./variable";

.main-content {
  position: relative;
  min-height: calc(100vh - 150px);
}
.hide-scroll {
  overflow: hidden;
  position: relative;
  @media (min-width: 768px) {
    padding-right: 10px;
  }
}
.hide-scroll::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba($white, 59%);
  z-index: 9;
  position: absolute;
  top: 0;
}
.container {
  padding-inline: 20px;
  max-width: 1380px;
  margin-inline: auto;
  width: 100%;
  overflow: hidden;
}
.section {
  overflow: hidden;
  padding-block: 90px;
  @media (max-width: 1024px) {
    padding-block: 45px;
  }
}

.bookDemoSection {
  .container {
    overflow: unset;
  }
}
