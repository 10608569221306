.blog-detail {
  max-width: 768px;
  margin-inline: auto;
  width: 100%;
  p,
  li,
  a,
  i {
    color: #475467;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .Content {
    label {
      color: #1943dc;
      margin-bottom: 20px;
      background: #f6f6f6;
      border-radius: var(--radius-2xl);
      padding: 10px 16px;
      display: inline-block;
    }
    p {
      color: #475467;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 40px;
    }
    .avatar {
      margin-right: 12px;
      img {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        object-fit: cover;
      }
    }
    .d-flex {
      display: flex;
      .text {
        h4 {
          color: #101828;
          margin: 0px;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
        p {
          color: #475467;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin: 0px;
          line-height: 20px;
        }
      }
    }
  }
}
