.inter {
  font-family: "Inter", sans-serif;
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.regular {
    font-weight: 400;
  }
}

// TWK font
@font-face {
  font-family: "TWK";
  src: url("../assets/fonts/TWK400.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Neue Font
@font-face {
  font-family: "Neue-bold";
  src: url("../assets/fonts/NeueHaasDisplay-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue-light";
  src: url("../assets/fonts/NeueHaasDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue-regular";
  src: url("../assets/fonts/NeueHaasDisplay-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue-medium";
  src: url("../assets/fonts/NeueHaasDisplay-Roman.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
